import { Record } from 'immutable';

const {
    HOROSCOPE_LIST_REQUEST,
    HOROSCOPE_LIST_SUCCESS,
    HOROSCOPE_LIST_FAILURE,

    FIND_USER_HOROSCOPE_REQUEST,
    FIND_USER_HOROSCOPE_SUCCESS,
    FIND_USER_HOROSCOPE_FAILURE,

    USER_ACTIVE_HOROSCOPES_REQUEST,
    USER_ACTIVE_HOROSCOPES_SUCCESS,
    USER_ACTIVE_HOROSCOPES_FAILURE,

    SELECT_SINGLE_HOROSCOPE_REQUEST,

    SELECT_SINGLE_LIFE_EVENT_REQUEST,

    SELECT_HOROSCOPE_PLAN_REQUEST,

    CHECK_IF_LIVE_REQUEST,
    CHECK_IF_LIVE_SUCCESS,
    CHECK_IF_LIVE_FAILURE,

    USER_PREVIOUS_HOROSCOPES_REQUEST,
    USER_PREVIOUS_HOROSCOPES_SUCCESS,
    USER_PREVIOUS_HOROSCOPES_FAILURE,

    GET_NEW_SINGLE_HOROSCOPE_REQUEST,
    GET_NEW_SINGLE_HOROSCOPE_SUCCESS,
    GET_NEW_SINGLE_HOROSCOPE_FAILURE,
} = require('./horoscopeActions').constants;

const {
    LOGOUT_SUCCESS,
} = require('../user/userActions').constants;

const InitialState = Record({
    isFetching: false,
    isFetchingPrevious: false,
    hasFetchedHorosopes: false,
    error: null,
    horoscopes: [],
    singleHoroscope: {},
    singleHoroscopesList: [],
    singleLifeEvent: {},
    userActiveHoroscopes: {},
    singleHoroscopeGenerated: {},
    horoscopePlan: {},
    isLiveChecking: false,
    horocopeStreamingLiveData: false,
    noUserHoroscope: {},
});

const initialState = new InitialState();

export default function horoscopeReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.merge(state);

    switch (type) {

    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('horoscopes', [])
            .set('singleHoroscope', {})
            .set('singleHoroscopeGenerated', {})
            .set('userActiveHoroscopes', {})
            .set('singleLifeEvent', {})
            .set('singleHoroscopesList', [])
            .set('horoscopePlan', {})
            .set('isLiveChecking', false)
            .set('horocopeStreamingLiveData', false)
            .set('noUserHoroscope', {})
            .set('error', null);

    case HOROSCOPE_LIST_REQUEST:
    case FIND_USER_HOROSCOPE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case HOROSCOPE_LIST_SUCCESS:
        return state.set('isFetching', false)
            .set('horoscopes', payload.data);

    case CHECK_IF_LIVE_REQUEST:
        return state.set('isLiveChecking', true)
            .set('error', null);

    case CHECK_IF_LIVE_SUCCESS:
        return state.set('isLiveChecking', false)
            .set('horocopeStreamingLiveData', payload.data);

    case CHECK_IF_LIVE_FAILURE:
        return state.set('isLiveChecking', false)
            .set('error', payload.data);

    case FIND_USER_HOROSCOPE_SUCCESS:
        return state.set('isFetching', false)
            .set('singleHoroscope', payload.data.horoscope)
            .set('singleHoroscopeGenerated', payload.data.horoscope);

    case USER_ACTIVE_HOROSCOPES_REQUEST:
        return state.set('isFetching', true)
            .set('hasFetchedHorosopes', false)
            .set('error', null);

    case USER_ACTIVE_HOROSCOPES_SUCCESS:
        return state.set('isFetching', false)
            .set('userActiveHoroscopes', payload)
            .set('hasFetchedHorosopes', true);

    case USER_ACTIVE_HOROSCOPES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case HOROSCOPE_LIST_FAILURE:
    case FIND_USER_HOROSCOPE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case SELECT_SINGLE_HOROSCOPE_REQUEST:
        return state.set('singleHoroscope', payload);

    case SELECT_SINGLE_LIFE_EVENT_REQUEST:
        return state.set('singleLifeEvent', payload);

    case SELECT_HOROSCOPE_PLAN_REQUEST:
        return state.set('horoscopePlan', payload);

    case GET_NEW_SINGLE_HOROSCOPE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_NEW_SINGLE_HOROSCOPE_SUCCESS:
        return state.set('isFetching', false)
            .set('singleHoroscope', payload.data.horoscope);

    case GET_NEW_SINGLE_HOROSCOPE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case USER_PREVIOUS_HOROSCOPES_REQUEST: {
        return state.set('isFetchingPrevious', true)
            .set('error', null);
    }

    case USER_PREVIOUS_HOROSCOPES_SUCCESS: {
        return state.set('isFetchingPrevious', false)
            .set('userActiveHoroscopes', payload)
            .set('hasFetchedHorosopes', false);
    }

    case USER_PREVIOUS_HOROSCOPES_FAILURE: {
        return state.set('isFetchingPrevious', false)
            .set('error', payload.data);
    }

    default:
        return state;
    }
}
